const BeSoulLogo = ({ width = '252', height = '48', style }: { width?: string; height?: string; style?: object }) => {
    return (
        <svg width={width} height={height} style={style} viewBox="0 0 252 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.2875 7.27569C23.1022 7.27569 25.7311 7.80677 28.2272 8.86895C30.7233 9.93112 32.8477 11.3651 34.6799 13.1707C36.4856 14.9764 37.9195 17.1273 38.9817 19.6234C40.0439 22.1196 40.575 24.7484 40.575 27.5632C40.575 30.431 40.0439 33.0865 38.9817 35.556C37.9195 38.0256 36.4856 40.1499 34.6799 41.9556C32.8742 43.7613 30.7233 45.1952 28.2272 46.2574C25.7311 47.3196 23.1022 47.8507 20.2875 47.8507C17.4196 47.8507 14.7642 47.3196 12.2946 46.2574C9.82509 45.1952 7.70075 43.7613 5.89505 41.9556C4.08936 40.1499 2.65543 38.0256 1.59326 35.556C0.531086 33.0865 0 30.431 0 27.5632V2.0445C0 0.929215 0.902846 0.0263672 2.01813 0.0263672C3.13341 0.0263672 4.03625 0.929215 4.03625 2.0445V15.4013C5.8685 12.9583 8.23183 11.0198 11.0731 9.5328C13.9941 8.01921 17.0479 7.27569 20.2875 7.27569ZM20.2875 43.7613C22.4915 43.7613 24.5893 43.3364 26.5543 42.4601C28.5193 41.5839 30.2453 40.442 31.7058 38.9815C33.1663 37.521 34.3347 35.8216 35.1844 33.83C36.0607 31.865 36.4856 29.7672 36.4856 27.5632C36.4856 25.3592 36.0607 23.2614 35.1844 21.2964C34.3082 19.3313 33.1663 17.6053 31.7058 16.1448C30.2453 14.6843 28.5193 13.516 26.5543 12.6662C24.5893 11.7899 22.4915 11.365 20.2875 11.365C18.0835 11.365 15.9857 11.7899 14.0207 12.6662C12.0556 13.5425 10.3296 14.6843 8.86914 16.1448C7.40865 17.6053 6.24026 19.3313 5.39052 21.2964C4.51423 23.2614 4.08936 25.3592 4.08936 27.5632C4.08936 29.7672 4.51423 31.865 5.39052 33.83C6.26681 35.795 7.40865 37.521 8.86914 38.9815C10.3296 40.442 12.0291 41.6104 14.0207 42.4601C15.9857 43.3099 18.0569 43.7613 20.2875 43.7613Z"
                fill="#96C9DA"
            />
            <path
                d="M65.6664 43.7616C69.1185 43.7616 72.1722 42.8322 74.8276 40.9999C77.1378 39.3801 78.917 37.3089 80.165 34.7331C80.5102 34.0427 81.2272 33.6178 81.9973 33.6178C83.5374 33.6178 84.5465 35.2642 83.8295 36.6185C83.3516 37.5479 82.7939 38.4242 82.1566 39.2739C80.882 40.9999 79.395 42.487 77.6955 43.735C75.996 44.9831 74.1107 45.9656 72.066 46.6825C70.0213 47.3995 67.8704 47.7713 65.6664 47.7713C62.7985 47.7713 60.1431 47.2402 57.6735 46.178C55.204 45.1158 53.0797 43.6819 51.274 41.8762C49.4683 40.0705 48.0343 37.9462 46.9722 35.4766C45.91 33.0071 45.3789 30.3516 45.3789 27.4838C45.3789 24.669 45.91 22.0401 46.9722 19.544C48.0343 17.0479 49.4683 14.9236 51.274 13.0913C53.0797 11.2856 55.204 9.85172 57.6735 8.78955C60.1431 7.72738 62.7985 7.19629 65.6664 7.19629C68.4811 7.19629 71.11 7.72738 73.6061 8.78955C76.1022 9.85172 78.2266 11.2856 80.0588 13.0913C81.8645 14.897 83.2984 17.0479 84.3606 19.544C85.4228 22.0401 85.9539 24.669 85.9539 27.4838V29.5019H78.6514H49.4417C49.7604 31.5466 50.3977 33.4585 51.3536 35.1845C52.3096 36.9106 53.5045 38.3976 54.965 39.6456C56.4255 40.8937 58.0453 41.8762 59.8776 42.5932C61.6832 43.3898 63.5952 43.7616 65.6664 43.7616ZM65.6664 11.3388C63.6217 11.3388 61.6832 11.7105 59.8776 12.4275C58.0719 13.1444 56.4255 14.1535 54.965 15.375C53.5045 16.6231 52.3096 18.1101 51.3536 19.8627C50.3977 21.6153 49.7604 23.5006 49.4417 25.4922H81.8645C81.5459 23.5006 80.9086 21.6153 79.9526 19.8627C78.9966 18.1101 77.8017 16.5965 76.3412 15.375C74.8807 14.127 73.2344 13.1444 71.4021 12.4275C69.5699 11.7105 67.658 11.3388 65.6664 11.3388Z"
                fill="#96C9DA"
            />
            <path
                d="M144.165 9.32233C144.165 8.20705 143.262 7.3042 142.147 7.3042H123.214C121.594 7.3042 120.08 7.5963 118.726 8.15394C117.345 8.73813 116.15 9.50821 115.115 10.5173C114.079 11.5263 113.282 12.6682 112.725 13.9959C112.167 15.3236 111.875 16.731 111.875 18.2446C111.875 19.6254 112.114 20.9265 112.565 22.2012C112.619 22.3339 112.672 22.4667 112.725 22.5995C113.282 23.9803 114.079 25.1752 115.115 26.2109C115.38 26.4764 115.646 26.7419 115.938 26.9544C116.761 27.6448 117.69 28.2024 118.726 28.6539C120.107 29.2646 121.594 29.5833 123.214 29.5833H141.43C143.262 29.5833 144.882 30.2206 146.263 31.4952C146.714 31.92 147.113 32.3715 147.405 32.876C148.015 33.885 148.334 35.08 148.334 36.4608C148.334 38.5055 147.644 40.2581 146.263 41.6389C144.882 43.0463 143.262 43.7367 141.43 43.7367H121.859C120.744 43.7367 119.841 44.6395 119.841 45.7548C119.841 46.8701 120.744 47.773 121.859 47.773H141.43C142.944 47.773 144.377 47.4543 145.679 46.8436C147.006 46.2328 148.175 45.4096 149.157 44.374C150.166 43.3649 150.936 42.1434 151.521 40.7626C152.078 39.3818 152.37 37.9479 152.37 36.4343C152.37 35.1066 152.158 33.8585 151.733 32.6901C151.68 32.5308 151.6 32.3715 151.521 32.1856C150.936 30.8579 150.166 29.716 149.157 28.707C148.892 28.4414 148.6 28.1759 148.308 27.9369C147.511 27.2996 146.661 26.7685 145.679 26.3702C144.351 25.786 142.944 25.5204 141.43 25.5204H123.214C121.169 25.5204 119.416 24.83 118.036 23.3961C117.611 22.9712 117.239 22.4933 116.92 21.9887C116.256 20.8734 115.911 19.6254 115.911 18.218C115.911 16.1733 116.602 14.5004 118.009 13.2258C119.416 11.9512 121.142 11.3139 123.187 11.3139H142.12C143.262 11.3405 144.165 10.4376 144.165 9.32233Z"
                fill="#96C9DA"
            />
            <path
                d="M192.298 41.3168C188.872 45.0079 184.119 47.4243 178.782 47.7961L179.977 45.3266C180.322 44.6096 180.481 43.8661 180.481 43.1491C180.481 40.7858 178.782 38.6083 176.259 38.21C172.807 37.6524 170.975 33.8551 172.674 30.8279L176.02 24.9063L177.348 22.543L180.056 25.9419L192.298 41.3168Z"
                fill="#F5C99A"
            />
            <path d="M177.375 22.4888L176.047 24.8521C175.542 25.0645 175.064 25.4097 174.666 25.8877L177.375 22.4888Z" fill="white" />
            <path
                d="M197.741 27.4554C197.741 32.7928 195.67 37.6788 192.297 41.2901L180.082 25.8886C179.073 24.6406 177.427 24.2954 176.046 24.853C175.542 25.0655 175.064 25.4107 174.665 25.8886L162.45 41.2901C158.759 37.3335 156.662 31.8899 157.06 25.9683C157.777 15.7714 166.062 7.64583 176.259 7.11475C187.996 6.504 197.741 15.8511 197.741 27.4554Z"
                fill="#96C9DA"
            />
            <path
                d="M222.842 47.7965C219.974 47.7965 217.319 47.2654 214.849 46.2032C212.38 45.141 210.255 43.7071 208.45 41.9014C206.644 40.0957 205.21 37.9714 204.148 35.5018C203.086 33.0323 202.555 30.3768 202.555 27.509V9.26618C202.555 8.15089 203.458 7.24805 204.573 7.24805C205.688 7.24805 206.591 8.15089 206.591 9.26618V27.509C206.591 29.713 207.016 31.8108 207.892 33.7758C208.768 35.7408 209.91 37.4668 211.371 38.9273C212.831 40.3878 214.531 41.5562 216.522 42.4059C218.487 43.2822 220.585 43.7071 222.789 43.7071C224.993 43.7071 227.091 43.2822 229.056 42.4059C231.021 41.5297 232.747 40.3878 234.207 38.9273C235.668 37.4668 236.836 35.7674 237.686 33.7758C238.562 31.8108 238.987 29.713 238.987 27.509V9.26618C238.987 8.15089 239.89 7.24805 241.005 7.24805C242.121 7.24805 243.023 8.15089 243.023 9.26618V27.509C243.023 30.3768 242.492 33.0323 241.404 35.5018C240.315 37.9714 238.854 40.0957 236.996 41.9014C235.137 43.7071 232.986 45.141 230.543 46.2032C228.206 47.2654 225.604 47.7965 222.842 47.7965Z"
                fill="#96C9DA"
            />
            <path
                d="M247.963 45.7796V2.01813C247.963 0.902848 248.866 0 249.981 0C251.096 0 251.999 0.902848 251.999 2.01813V45.7796C251.999 46.8949 251.096 47.7977 249.981 47.7977C248.892 47.7977 247.963 46.8949 247.963 45.7796Z"
                fill="#96C9DA"
            />
        </svg>
    );
};

export default BeSoulLogo;
