import React from 'react';
import { Link } from 'react-router-dom';

interface LinkProps {
    children: React.ReactNode;
    to: string;
    sx?: Object;
    state?: Object;
    target?: '_blank' | undefined;
    onClick?: () => void;
}

const CustomLink = ({ children, to, sx, onClick, target, state }: LinkProps) => {
    return (
        <Link style={{ textDecoration: 'none', ...sx }} to={to} target={target} onClick={onClick} state={state}>
            {children}
        </Link>
    );
};

export default CustomLink;
