const legacy = {
    sharedLegacy: {
        step1: {
            title: ' ha compartido un legado especial contigo en Be Soul',
            subtitle: 'Ingresa tu Nombre y apellido',
            input1: { label: 'Nombre' },
            input2: { label: 'Apellido' },
            button1: 'Continuar',
            button2: 'Omitir'
        },
        step2: {
            title: 'Verify OTP',
            description: 'Please enter the OTP code sent to your email'
        }
    },
    legacyById: {
        downloadApp: {
            title: {
                1: 'Descarga la  ',
                2: 'Aplicación de Be Soul',
                3: ' y empieza a crear legados únicos para compartir con tus seres queridos'
            },
            description:
                'Para acceder al contenido de este legado desde la app, asegúrate de iniciar sesión con el mismo correo al que te lo compartieron.',
            shareWithMe: {
                title: 'Compartidos conmigo',
                button: 'Ver todos'
            },
            unauthorized:
                'Este legado ya no está disponible. El propietario puede haberlo eliminado o te ha eliminado de la lista de personas con acceso compartido.'
        },
        legacyInfo: {
            createdBy: 'Creado por: ',
            createdAt: 'Fecha de creación: ',
            legacyFiles: {
                photosAndVideos: 'Fotos y videos',
                files: 'Archivos',
                audios: 'Notas de voz'
            }
        }
    }
};

export default legacy;
