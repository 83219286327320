import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { themes } from './constants';

export interface ToastCodeProps extends ToastOptions<{}> {
    theme: (typeof themes)[number];
}

const config: ToastCodeProps = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
};

const showToastMessage = (type: string, message: string) => {
    if (type === 'error') {
        return toast.error(message, config);
    }
    if (type === 'warning') {
        return toast.warning(message, config);
    }

    return toast.success(message, config);
};

export default showToastMessage;
