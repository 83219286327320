import { Box, Button, Drawer, IconButton, List, ListItem, Menu, MenuItem, Stack, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import CustomLink from 'ui-component/CustomLink';
import Text from 'ui-component/Text/Text';
import { ReactComponent as BeSoulLogo } from '../../../assets/icons/svg/besoul_logo2.svg';
// import { ReactComponent as LegacyIcon } from '../../../assets/icons/svg/legacy_icon.svg';
import PersonIcon from '@mui/icons-material/Person';
import useAuth from 'hooks/useAuth';
import useLocalStorage from 'hooks/useLocalStorage';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetUser } from 'store/slices/user';
import { ReactComponent as MobileMenuIcon } from '../../../assets/icons/svg/mobile_menu_icon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/svg/plus_icon.svg';
import { ReactComponent as SignedOutline } from '../../../assets/images/svg/signed-outline.svg';
import PACKAGE from '../../../../package.json';
import CustomButton from 'ui-component/CustomButton';
import LanguagesIcon from 'ui-component/Icons/LanguagesIcon';
import ArrowUpIcon from 'ui-component/Icons/ArrowUpIcon';
import ArrowDownIcon from 'ui-component/Icons/ArrowDownIcon';
import AppStore from 'ui-component/Buttons/AppStore';
import GooglePlay from 'ui-component/Buttons/GooglePlay';

interface MenuListProps {
    handleClose: () => void;
    handleCreateMemorialClick: () => void;
}

const MenuList = ({ handleClose, handleCreateMemorialClick }: MenuListProps) => {
    const { t } = useTranslation();
    const itemStyles = { xs: { marginLeft: 2 } };

    return (
        <Box width={'300px'} height={'calc(100vh - 73px)'}>
            <List>
                {/* <ListItem sx={{ marginTop: 1 }}>
                    <LegacyIcon />
                    <CustomLink onClick={() => handleClose()} to={'/digital-legacy'}>
                        <Text fontWeight={500} preset="titleMedium" sx={itemStyles}>
                            {t('common.header.legacy')}
                        </Text>
                    </CustomLink>
                </ListItem> */}
                <ListItem sx={{ marginTop: 1 }}>
                    <SignedOutline />
                    <CustomLink to={'/app/memorial/feed'}>
                        <Text fontWeight={500} preset="titleMedium" sx={itemStyles}>
                            {t('common.header.memorials')}
                        </Text>
                    </CustomLink>
                </ListItem>
                <ListItem sx={{ marginTop: 1 }}>
                    <PlusIcon />
                    <Button onClick={() => handleCreateMemorialClick()}>
                        <Text fontWeight={500} preset="titleMedium" sx={{ xs: { marginLeft: 1.2 } }}>
                            {t('common.header.create')}
                        </Text>
                    </Button>
                </ListItem>
            </List>
        </Box>
    );
};

const LanguageMenu = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const changeLanguage = (locale: string) => {
        i18n.changeLanguage(locale);
        handleClose();
    };

    return (
        <>
            <CustomButton onClick={handleClick}>
                <LanguagesIcon color="#45565F" style={{ marginRight: '5px' }} />
                {open ? <ArrowUpIcon color="#45565F" /> : <ArrowDownIcon color="#45565F" />}
            </CustomButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ sx: { backgroundColor: theme.palette.common.white } }}
            >
                <MenuItem onClick={() => changeLanguage('es-419')}>
                    <Text preset="titleLarge" fontFamily="Merriweather" fontWeight={700}>
                        {t('common.header.languages.spanish')}
                    </Text>
                </MenuItem>
                <MenuItem onClick={() => changeLanguage('en-US')}>
                    <Text preset="titleLarge" fontFamily="Merriweather" fontWeight={700}>
                        {t('common.header.languages.english')}
                    </Text>
                </MenuItem>
            </Menu>
        </>
    );
};

interface AuthHeaderProps {
    openAuthDrawer: boolean;
    setOpenAuthDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AuthHeader({ openAuthDrawer, setOpenAuthDrawer, setOpenModal }: AuthHeaderProps) {
    const [isDrawerOpen, setIsDrawerOpen] = useLocalStorage('isDrawerOpen', false);
    const APP_BAR_HEIGHT = '74px';
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { logout } = useAuth();
    const useInfo = useSelector((state: any) => state.user.userInfo);
    const dispatch = useDispatch();
    const handleClick = () => {
        setIsDrawerOpen(!isDrawerOpen);
        setOpenAuthDrawer((prev) => !prev);
    };
    const handleClose = () => {
        setOpenAuthDrawer(false);
        setIsDrawerOpen(false);
    };
    const handleCreateMemorialClick = () => {
        setOpenModal(true);
    };

    const handleClickProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseProfileMenu = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('jwt');
        dispatch(resetUser());
        logout();
        navigate('/');
    };

    return (
        <AppBar
            position="relative"
            elevation={0}
            sx={{
                borderBottom: `1px solid #D6DDE1`,
                backgroundColor: theme.palette.common.white,
                height: APP_BAR_HEIGHT,
                justifyContent: 'center',
                zIndex: 1
            }}
        >
            <Toolbar disableGutters>
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    <Stack direction="row" alignItems="center">
                        <IconButton onClick={handleClick} size="large" edge="start" color="inherit" aria-label="menu">
                            <MobileMenuIcon />
                        </IconButton>
                        <CustomLink to={'/'}>
                            <BeSoulLogo />
                        </CustomLink>
                        <Drawer
                            variant="persistent"
                            anchor="left"
                            open={openAuthDrawer}
                            onClose={handleClose}
                            PaperProps={{
                                sx: {
                                    backgroundColor: theme.palette.common.white,
                                    marginTop: APP_BAR_HEIGHT,
                                    width: '320px',
                                    height: 'calc(100vh - 75px)'
                                }
                            }}
                        >
                            <MenuList handleClose={handleClose} handleCreateMemorialClick={handleCreateMemorialClick} />

                            <Stack
                                direction="row"
                                columnGap={{ xs: 1, md: 1 }}
                                justifyContent={{ xs: 'start', md: 'start' }}
                                width={{ xs: '100%' }}
                                margin={{ xs: 0 }}
                                marginBottom={{ xs: '20px !important' }}
                                paddingLeft="25px"
                            >
                                <AppStore width={{ xs: '94.81px', md: '90px' }} />
                                <GooglePlay width={{ xs: '94.81px', md: '90px' }} />
                            </Stack>

                            <Stack direction="column" rowGap={1} sx={{ paddingX: '10px', backgroundColor: '#F6F8F9', padding: '10px' }}>
                                <CustomLink to="/policy-privacy">
                                    <Text preset="titleSmall" lineHeight={{ xs: '20px', md: '24px' }}>
                                        {t('common.footer.privacyPolicy')}
                                    </Text>
                                </CustomLink>
                                <CustomLink to="/terms-conditions/">
                                    <Text preset="titleSmall" lineHeight={{ xs: '20px', md: '24px' }}>
                                        {t('common.footer.termsAndConditions')}
                                    </Text>
                                </CustomLink>

                                <Box>
                                    <Text preset="titleSmall" fontWeight={600} sx={{ xs: { width: '100%', fontSize: '12px' } }}>
                                        ©2024 Be soul from Sitio Uno | {PACKAGE.version}
                                    </Text>
                                    {/* <Text preset="titleSmall" sx={{ xs: { width: '100%' } }}>
                                        <a
                                            href="https://sitiouno.us/"
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            {t('common.footer.copyright1')}
                                        </a>
                                        {` | ${PACKAGE.version} - ${t('common.footer.copyright2')}`}
                                    </Text> */}
                                </Box>
                            </Stack>
                        </Drawer>
                    </Stack>

                    <Stack direction="row" columnGap={2}>
                        <LanguageMenu />

                        <Box>
                            <IconButton
                                onClick={handleClickProfileMenu}
                                color="inherit"
                                sx={{ backgroundColor: '#ECF1FA', borderRadius: '50px', width: '50px', height: '50px', padding: '5px' }}
                            >
                                {useInfo.profilePhoto ? (
                                    <Box
                                        component="img"
                                        src={useInfo.profilePhoto}
                                        sx={{ borderRadius: '50px', width: '50px', height: '50px' }}
                                    />
                                ) : (
                                    <PersonIcon sx={{ width: '50px', height: '50px', color: '#fff' }} />
                                )}
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseProfileMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                }}
                            >
                                <MenuItem onClick={handleLogout}>{t('common.header.logout')}</MenuItem>
                            </Menu>
                        </Box>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
export default AuthHeader;
