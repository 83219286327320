const PaperAndPencil = ({ width = '147', height = '146' }: { width?: string; height?: string }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 147 146" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" width={width} height={height} rx="73" fill="#E8DDD9" />
            <path d="M136.597 108.14L67.6049 120.675L11.0234 97.5894L70.9028 92.9785L136.612 108.14H136.597Z" fill="#1C1006" />
            <path
                d="M64.0627 45.431C77.0671 69.7985 106.046 94.7756 142.464 102.153C142.464 102.153 92.5723 115.986 73.3314 115.017C73.3314 115.017 30.1764 97.5735 5.05859 41.5234C16.5781 44.3994 33.615 45.3997 64.0471 45.431H64.0627Z"
                fill="white"
            />
            <path
                d="M126.729 98.9969C131.778 100.779 136.92 102.217 142.172 103.295V101.029C124.291 105.984 106.004 110.469 87.5912 112.923C84.9497 113.283 82.2926 113.564 79.6355 113.736C77.7442 113.861 75.4466 114.143 73.6647 113.877C73.3834 113.83 73.9617 113.971 73.6335 113.877C73.3677 113.799 73.102 113.642 72.8519 113.533C71.9766 113.127 71.117 112.705 70.2573 112.267C62.3015 108.203 54.8772 103.014 48.0311 97.2775C34.6048 86.0238 23.3666 72.3473 14.4887 57.2641C13.7228 55.9668 11.6908 57.1391 12.4567 58.452C25.5861 80.7876 44.2486 100.888 67.2094 113.33C68.9131 114.252 70.6637 115.205 72.4612 115.924C73.4459 116.315 74.4306 116.253 75.4934 116.253C77.8849 116.253 80.2919 116.081 82.6677 115.846C94.9062 114.658 107.02 112.048 118.977 109.282C126.948 107.437 134.904 105.483 142.797 103.295C143.86 102.998 143.969 101.279 142.797 101.029C137.561 99.9503 132.403 98.5123 127.355 96.7305C125.932 96.2303 125.323 98.4967 126.729 98.9969Z"
                fill="#3A302E"
            />
            <path
                d="M10.5909 50.2616C8.99658 47.1981 7.49608 44.0877 6.07373 40.9303L4.74516 42.6497C12.0914 44.4784 19.6877 45.1974 27.2214 45.6976C39.4755 46.4947 51.7765 46.5885 64.0619 46.6041L63.0459 46.0258C74.2684 66.8765 93.2279 83.4133 114.329 93.7762C115.673 94.4326 116.861 92.4163 115.517 91.7442C94.7597 81.5533 76.1128 65.3448 65.0778 44.8223C64.8903 44.4628 64.4683 44.244 64.0619 44.244C51.9485 44.2283 39.8038 44.1345 27.706 43.3687C20.2191 42.8998 12.6697 42.1808 5.37036 40.3677C4.30751 40.1019 3.63541 41.1804 4.0418 42.087C5.46415 45.2443 6.96465 48.3391 8.55893 51.4182C9.26229 52.7624 11.2786 51.5745 10.5909 50.2303V50.2616Z"
                fill="#3A302E"
            />
            <path
                d="M26.8085 60.3588C28.3715 60.6402 29.7939 61.4373 31.3569 61.6249C33.0137 61.8281 34.6236 61.1716 36.2804 61.3279C37.9372 61.4842 39.2971 62.2813 40.8914 62.3439C42.6576 62.422 44.2987 61.6249 46.065 61.7655C47.4717 61.875 48.8159 62.4533 50.2539 62.4064C51.8481 62.3595 53.3018 61.5623 54.8648 61.4217C56.4278 61.281 58.1315 61.9062 59.7883 61.6718C61.3513 61.4529 62.7424 60.5933 64.2742 60.2338C65.2589 59.9993 64.8369 58.4988 63.8522 58.7333C62.1797 59.124 60.6636 60.1556 58.913 60.1869C57.35 60.2181 55.8651 59.718 54.2865 59.9524C52.9266 60.1556 51.6293 60.8121 50.2382 60.8746C48.7534 60.9371 47.331 60.265 45.8618 60.2025C44.1737 60.1244 42.5638 60.9059 40.8757 60.8121C39.4846 60.7339 38.2186 60.0618 36.8431 59.8743C35.2488 59.6398 33.7952 60.1713 32.2166 60.1556C30.466 60.14 28.903 59.2022 27.1993 58.8896C26.2146 58.7176 25.7926 60.2181 26.7773 60.3901L26.8085 60.3588Z"
                fill="#3A302E"
            />
            <path
                d="M31.3222 65.6886C32.8852 65.9387 34.3232 66.7046 35.8862 66.8765C37.543 67.0485 39.1373 66.3764 40.7941 66.4858C42.4509 66.5952 43.8264 67.3767 45.4206 67.408C46.8586 67.4392 48.2028 66.8609 49.6252 66.7515C51.3758 66.5952 53.0326 67.3923 54.7832 67.3142C56.268 67.2517 57.581 66.5327 59.019 66.2982C60.7852 66.0169 62.5358 66.689 64.302 66.4076C65.865 66.1575 67.2405 65.2666 68.7566 64.8759C69.7257 64.6258 69.3193 63.1096 68.3346 63.3754C66.6778 63.7974 65.1773 64.8446 63.4267 64.9228C61.9887 64.9853 60.5976 64.5476 59.1596 64.7196C57.7216 64.8915 56.2837 65.673 54.7675 65.7668C53.0951 65.8762 51.4852 65.0947 49.7971 65.1885C48.3122 65.2666 46.9055 65.9387 45.4206 65.8762C44.0296 65.8293 42.7479 65.1885 41.3724 65.0165C39.7781 64.8133 38.3402 65.3604 36.7615 65.3917C35.0109 65.4229 33.4323 64.5007 31.7286 64.2194C30.7439 64.0631 30.3218 65.5636 31.3066 65.7199L31.3222 65.6886Z"
                fill="#3A302E"
            />
            <path
                d="M35.8222 71.0342C37.2758 71.2374 38.6044 71.8782 40.0423 72.0971C41.8554 72.3628 43.5123 71.5813 45.3097 71.6594C46.8884 71.7376 48.3576 72.4878 49.9519 72.5034C51.3899 72.5034 52.7185 71.9251 54.1408 71.7688C55.8914 71.5813 57.5482 72.3315 59.2988 72.2221C60.768 72.1283 62.081 71.3937 63.5033 71.128C65.2695 70.7997 67.0201 71.4406 68.7863 71.128C70.3494 70.8466 71.6936 69.9401 73.2097 69.5181C74.1788 69.2524 73.7724 67.7362 72.7877 68.0176C71.1309 68.4708 69.646 69.5493 67.911 69.6587C66.4887 69.7525 65.082 69.3305 63.644 69.5181C62.206 69.7056 60.7837 70.5184 59.2831 70.6434C57.6107 70.7841 55.9852 70.0339 54.3127 70.1589C52.8279 70.2527 51.4368 70.9404 49.9519 70.9248C48.5608 70.9248 47.2635 70.2839 45.9037 70.1276C44.3094 69.9401 42.8714 70.534 41.2928 70.5809C39.5422 70.6434 37.9479 69.7525 36.2442 69.5024C35.2595 69.3618 34.8375 70.8623 35.8222 71.0029V71.0342Z"
                fill="#3A302E"
            />
            <path
                d="M40.5257 76.396C42.0887 76.5836 43.5423 77.3339 45.1366 77.412C47.0591 77.4902 48.8409 76.6149 50.7791 76.9275C52.0139 77.1307 53.2018 77.5839 54.4678 77.5839C56.0621 77.5839 57.5313 76.8024 59.11 76.7086C60.6887 76.6149 62.3455 77.287 64.0179 77.115C65.409 76.9587 66.6438 76.2554 68.0036 75.974C69.7542 75.6145 71.5204 76.2241 73.2866 75.8802C74.834 75.5833 76.1782 74.6455 77.6787 74.1922C78.6478 73.9108 78.2258 72.3947 77.2567 72.6917C75.6155 73.1762 74.1463 74.2703 72.4113 74.4266C70.989 74.5517 69.5823 74.1609 68.1443 74.3641C66.4249 74.6142 64.9088 75.6145 63.1426 75.5989C61.5796 75.5676 60.1103 75.0206 58.5317 75.2081C57.1562 75.3801 55.8589 76.0053 54.4678 76.0365C52.8892 76.0522 51.4043 75.3019 49.81 75.2863C48.2157 75.2707 46.7152 75.974 45.1366 75.8802C43.5579 75.7865 42.0887 75.0675 40.5257 74.8643C39.5253 74.7392 39.5409 76.3023 40.5257 76.4273V76.396Z"
                fill="#3A302E"
            />
            <path
                d="M45.0354 81.7253C46.6141 81.8973 48.0677 82.6006 49.662 82.6475C51.2562 82.6944 52.7411 81.9754 54.3354 81.9754C55.9297 81.9754 57.4145 82.6944 59.0088 82.6475C60.4468 82.6006 61.7598 81.9754 63.1665 81.7566C64.9171 81.4909 66.5895 82.1786 68.3401 82.0223C69.8249 81.8816 71.091 81.1157 72.5134 80.7875C74.2639 80.3968 76.0458 80.9594 77.7964 80.6C79.3438 80.2717 80.6723 79.3026 82.1572 78.8337C83.1106 78.5368 82.7043 77.0206 81.7352 77.3332C80.2503 77.8021 78.9061 78.8181 77.3744 79.0995C75.8426 79.3808 74.2327 78.9119 72.6697 79.1776C70.9503 79.4589 69.4655 80.4749 67.6992 80.5062C66.0893 80.5218 64.6045 79.9904 62.9789 80.2248C61.4471 80.4436 60.0404 81.1939 58.4618 81.1001C56.9769 81.0063 55.5858 80.3968 54.0853 80.4593C52.5848 80.5218 51.1937 81.1783 49.7088 81.1157C48.1458 81.0532 46.6453 80.3499 45.0823 80.1936C44.082 80.0842 44.0976 81.6472 45.0823 81.7566L45.0354 81.7253Z"
                fill="#3A302E"
            />
            <path
                d="M49.5452 87.0557C51.1238 87.1963 52.6087 87.8841 54.1874 87.8997C55.766 87.9153 57.2665 87.1807 58.8452 87.1338C60.4238 87.0869 61.9399 87.7903 63.5342 87.7121C65.0191 87.6496 66.332 86.9306 67.77 86.7118C69.4737 86.4461 71.1461 87.0869 72.8498 86.915C74.3191 86.7587 75.5851 85.9616 76.9919 85.6021C78.7268 85.1644 80.5243 85.7115 82.2592 85.3051C83.8066 84.9612 85.1039 83.9609 86.5888 83.4607C87.5423 83.1325 87.1359 81.632 86.1668 81.9602C84.6976 82.4604 83.3534 83.492 81.8372 83.8046C80.1023 84.1641 78.3048 83.6483 76.5698 84.1016C75.1006 84.4767 73.8189 85.3363 72.2715 85.3989C70.8179 85.4614 69.3956 84.9768 67.9263 85.1332C66.2383 85.3207 64.6752 86.2273 62.9559 86.1491C61.5648 86.0866 60.2519 85.5395 58.8452 85.5708C57.2665 85.6021 55.766 86.3523 54.1874 86.3367C52.6087 86.321 51.1082 85.6333 49.5452 85.4926C48.5448 85.3989 48.5448 86.9775 49.5452 87.0557Z"
                fill="#3A302E"
            />
            <path
                d="M54.0549 92.4009C55.6336 92.5103 57.1341 93.1668 58.7127 93.1512C60.2914 93.1355 61.7763 92.3697 63.3549 92.3071C64.9336 92.2446 66.4653 92.8855 68.0596 92.7917C69.6539 92.6979 71.1544 91.8226 72.7643 91.6663C74.3742 91.51 75.8904 92.0258 77.469 91.7913C79.0477 91.5569 80.2825 90.6816 81.7673 90.3534C83.2522 90.0251 84.9247 90.3846 86.472 90.0877C88.1288 89.775 89.4887 88.6653 91.0517 88.1026C91.9895 87.7587 91.5831 86.2582 90.6297 86.6021C89.1604 87.1335 87.8475 88.1808 86.3314 88.5403C84.8152 88.8998 83.1741 88.4934 81.6267 88.7903C79.9542 89.1186 78.5163 90.1971 76.7813 90.3065C75.3589 90.4003 73.9679 89.9626 72.5299 90.1033C70.9825 90.2752 69.5914 91.088 68.044 91.2287C66.4966 91.3693 64.9336 90.6816 63.3393 90.7441C61.745 90.8066 60.2758 91.5725 58.6971 91.5882C57.1185 91.6038 55.6023 90.9473 54.0393 90.8379C53.039 90.7754 53.039 92.3384 54.0393 92.4009H54.0549Z"
                fill="#3A302E"
            />
            <path
                d="M106.06 27.16C106.216 26.6286 106.779 26.3316 107.31 26.4879L111.249 27.6758C111.781 27.8321 112.078 28.3948 111.921 28.9262L96.3692 80.3497L90.4922 78.5678L106.06 27.16Z"
                fill="#8FC1D4"
            />
            <path
                d="M90.5235 78.7397L96.4004 80.5216L95.5876 83.1944C95.4313 83.7258 94.8687 84.0228 94.3372 83.8665L91.3362 86.5861C90.8048 86.4298 89.5544 81.9439 89.7107 81.4125L90.5235 78.7397Z"
                fill="#1C1006"
            />
            <path
                d="M106.751 27.5668C107.188 26.879 109.58 27.9888 110.158 28.1763C110.705 28.3483 111.08 28.3639 111.143 28.911C111.221 29.5674 110.58 30.771 110.392 31.3962C109.22 35.2881 108.032 39.18 106.86 43.072C103.859 53.0128 100.842 62.938 97.8413 72.8788C97.1067 75.3015 96.3721 77.7398 95.6375 80.1625L96.6065 79.6154C94.6528 79.0215 92.699 78.4275 90.7296 77.8336L91.2766 78.8027C95.8094 63.8289 100.342 48.8395 104.875 33.8658C105.531 31.7088 106.188 29.5362 106.844 27.3792C107.141 26.4101 105.625 26.0037 105.344 26.9572C100.811 41.931 96.2783 56.9203 91.7455 71.8941C91.0891 74.0511 90.4326 76.2237 89.7761 78.3806C89.6511 78.787 89.9324 79.2247 90.3232 79.3497C92.277 79.9437 94.2307 80.5376 96.2002 81.1316C96.6065 81.2566 97.0442 80.9753 97.1692 80.5845C102.218 63.8914 107.266 47.1983 112.315 30.5053C112.612 29.5049 113.159 28.317 112.299 27.4261C111.518 26.6133 109.814 26.4414 108.814 26.1288C107.454 25.7224 106.313 25.3629 105.422 26.7853C104.891 27.6449 106.235 28.4264 106.766 27.5668H106.751Z"
                fill="#3A302E"
            />
            <path
                d="M90.3071 79.49C92.2609 80.084 94.2147 80.6779 96.1841 81.2719L95.637 80.3028L95.1994 81.7564L94.918 82.7099C94.918 82.7099 94.8555 82.8662 94.8555 82.9443C94.8242 83.1006 94.9649 82.7411 94.8711 82.8974C94.8086 83.0225 94.9962 82.788 94.9024 82.8662C94.8711 82.8974 94.8399 82.9287 94.8086 82.9599C94.7148 83.0537 94.6211 83.1475 94.5116 83.2413C94.2616 83.4757 94.0115 83.6946 93.7614 83.8978C93.1674 84.3979 92.5579 84.8825 91.9483 85.3514C91.8076 85.4608 91.6513 85.5858 91.5106 85.6952C91.4169 85.7734 91.6044 85.6327 91.4325 85.7578C91.3856 85.789 91.3387 85.8359 91.2918 85.8672C91.1824 85.9453 91.073 86.0391 90.9636 86.1173L92.1046 86.5862C91.7138 85.5233 91.3231 84.4604 90.9792 83.382C90.776 82.7724 90.6041 82.1472 90.4634 81.522C90.4634 81.4907 90.4634 81.7095 90.4634 81.5689C90.4322 81.4126 90.4634 81.522 90.4634 81.5689C90.479 81.6783 90.4634 81.6314 90.479 81.5376C90.5572 81.2875 90.6353 81.0374 90.7135 80.7717L91.2606 78.9586C91.5575 77.9895 90.0414 77.5831 89.76 78.5366L89.2911 80.0996C89.1505 80.5529 88.916 81.0531 88.916 81.5376C88.916 82.1472 89.1661 82.8036 89.3537 83.382C89.5881 84.1478 89.8538 84.8981 90.1195 85.6483C90.2758 86.1016 90.4478 86.5549 90.6197 87.0082C90.776 87.4302 91.3231 87.8053 91.7607 87.4771C92.7298 86.7425 93.6989 85.9766 94.6523 85.1951C95.1212 84.8043 95.6214 84.4136 96.0278 83.929C96.356 83.5383 96.4498 83.0537 96.5905 82.5848C96.778 81.9596 96.9656 81.35 97.1531 80.7248C97.2782 80.3184 96.9968 79.8808 96.6061 79.7557C94.6523 79.1618 92.6985 78.5679 90.7291 77.9739C89.76 77.6769 89.3537 79.1931 90.3071 79.4744V79.49Z"
                fill="#3A302E"
            />
        </svg>
    );
};

export default PaperAndPencil;
