import { Box, Input, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import CustomButton from 'ui-component/CustomButton';
import PaperAndPencil from 'ui-component/Icons/PaperAndPencil';
import Text from 'ui-component/Text/Text';

const RegisterForm = ({
    handleNextStep,
    registerInfo,
    setRegisterInfo
}: {
    handleNextStep: () => void;
    registerInfo: object;
    setRegisterInfo: Dispatch<SetStateAction<any>>;
}) => {
    const location = useLocation();
    const queries = new URLSearchParams(location.search);
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            name: '',
            lastName: ''
        },
        onSubmit: async (values) => {
            setRegisterInfo(values);
            handleNextStep();
        }
    });
    return (
        <Stack
            height="100%"
            direction="column"
            gap={2}
            alignItems="center"
            justifyContent="center"
            component="form"
            onSubmit={formik.handleSubmit}
        >
            <PaperAndPencil width="146" height="146" />
            <Box>
                <Text preset={{ md: 'headlineSmall' }} fontFamily="Merriweather" fontWeight={700}>
                    {queries.get('user_name')}
                    {t('legacy.sharedLegacy.step1.title')}
                </Text>
                <Text preset={{ md: 'titleMedium' }} fontWeight={400}>
                    {t('legacy.sharedLegacy.step1.subtitle')}
                </Text>
            </Box>

            <Box width="100%">
                <Text preset="titleSmall" textColor="#889EA8" fontWeight={600} sx={{ xs: { marginBottom: '5px' } }}>
                    {t('legacy.sharedLegacy.step1.input1.label')}
                </Text>
                <Box width="100%">
                    <Input
                        disableUnderline
                        fullWidth
                        sx={{
                            borderRadius: '4px',
                            backgroundColor: '#EFF2F7',
                            border: `1px solid ${formik.touched.name && Boolean(formik.errors.name) ? 'red' : '#455A6480'}`,
                            padding: '14px 16px'
                        }}
                        inputProps={{ maxLength: 140 }}
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    {formik.touched.name && (
                        <Text preset="labelSmall" textColor="red">
                            {formik.errors.name}
                        </Text>
                    )}
                </Box>
            </Box>

            <Box width="100%">
                <Text preset="titleSmall" textColor="#889EA8" fontWeight={600} sx={{ xs: { marginBottom: '5px' } }}>
                    {t('legacy.sharedLegacy.step1.input2.label')}
                </Text>
                <Box width="100%">
                    <Input
                        disableUnderline
                        fullWidth
                        sx={{
                            borderRadius: '4px',
                            backgroundColor: '#EFF2F7',
                            border: `1px solid ${formik.touched.lastName && Boolean(formik.errors.lastName) ? 'red' : '#455A6480'}`,
                            padding: '14px 16px'
                        }}
                        inputProps={{ maxLength: 140 }}
                        id="lastName"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    />
                    {formik.touched.lastName && (
                        <Text preset="labelSmall" textColor="red">
                            {formik.errors.lastName}
                        </Text>
                    )}
                </Box>
            </Box>

            <CustomButton
                fullWidth
                disabled={!Boolean(formik.values.name) || !Boolean(formik.values.lastName)}
                variant="contained"
                buttonColor="secondary"
                type="submit"
                padding={{ xs: '14px 50px', lg: '14px 123px' }}
                width={{ xs: '100%', lg: '436px' }}
                sx={{
                    xs: {},
                    md: { borderRadius: '14px' }
                }}
            >
                <Text preset={{ md: 'titleMedium' }} textColor="inherit" fontWeight={600}>
                    {t('legacy.sharedLegacy.step1.button1')}
                </Text>
            </CustomButton>

            <CustomButton
                fullWidth
                onClick={handleNextStep}
                variant="contained"
                type="submit"
                buttonColor="secondary"
                padding={{ xs: '14px 50px', lg: '14px 123px' }}
                width={{ xs: '100%', lg: '436px' }}
                sx={{
                    xs: {},
                    md: { borderRadius: '14px' }
                }}
            >
                <Text preset={{ md: 'titleMedium' }} textColor="inherit" fontWeight={600}>
                    {t('legacy.sharedLegacy.step1.button2')}
                </Text>
            </CustomButton>
        </Stack>
    );
};

export default RegisterForm;
