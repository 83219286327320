const memorials = {
    seo: {
        title: 'Honoring Loved Ones | Be Soul Memorials',
        description:
            'Explore our Memorial Section, a compassionate space for cherished memories of humans and pets. Craft enduring digital memorials with photos, stories, and tributes'
    },
    banner: {
        title: 'Memorial',
        subtitle: 'Share the life story of your loved ones',
        label: 'Coming soon'
    },
    commemorate: {
        title: 'Commemorate those who are no longer here:',
        item1: 'Tell the story of your loved one or pet',
        item2: 'Get help from AI to put together your commemorations',
        item3: 'Share memories through photos and videos',
        item4: 'Friends and family can leave their dedications through text, photos and videos'
    },
    howItWorks: {
        title: 'How does it work?',
        item1: 'Select that special person or pet in your life whose memory you want to honor',
        item2: 'Tell their story, using your own words or with the help of AI, and share your photo',
        item3: 'Post your memorial so your loved ones can express their condolences and share memories'
    },
    button: 'Download Be Soul',
    create: {
        title: 'Create memorial',
        selectTypeTitle: 'What do you want to create?',
        legacyLabel: 'Legacies',
        memorialLabel: 'Memorials',
        lovedOneLabel: 'A loved one',
        petLabel: 'A pet',
        forWhoTitle: 'Who is this memorial?',
        howTitle: 'How do you want to create this memorial?',
        assistanceWrite: 'We help you write',
        noAssistanceWrite: 'No writing assistance',
        assistanceWriteDescription: 'We assist you in writing your memorial with guided questions and artificial intelligence',
        noAssistanceWriteDescription:
            'Complete a traditional form and create your memorial according to your preferences without assistance',
        form: {
            nameLabel: `What was that person's name?`,
            petNameLabel: `What was your pet's name?`,
            namePlaceHolder: 'Name and Surname',
            petNamePlaceHolder: 'Name',
            deathLabel: 'Where did he pass away?',
            deathPlaceHolder: 'State/City, Country',
            birthLabel: 'Where was he born?',
            birthLabel2: 'When was he born?',
            storyLabel: 'Tell us their story',
            storyPlaceholder: 'Start writing here',
            partingWordsLabel: 'Write some parting words to honor him',
            partingWordsPlaceholder: 'Write something',
            nextLabel: 'Next',
            doneLabel: 'Done',
            prevLabel: 'Previous'
        }
    },
    edit: {
        editLabel: 'Edit memorial'
    },
    draft: {
        memorialCardTitle: 'Memorial',
        galleryCardTitle: 'Gallery',
        uploadPhotoLabel: 'Upload a photo:',
        inMemoryLabel: 'In memory of:',
        nameLabel: 'Name and Surname',
        datePlaceBirthLabel: 'Date and place of birth',
        datePlaceDeathLabel: 'Date and place of death',
        partingWordsLabel: 'Parting words:',
        storyLabel: 'Their story:',
        partingWordsEmptyState:
            'Include her a meaningful phrase, a poem, a quote, or any other message that reflects the life or personality of your loved one',
        editButton: 'Edit',
        storyEmptyState: 'Tell about their achievements, describe the essence of your loved and the reason why will be missed so much',
        editStoryButton: 'Edit Story',
        previewButton: 'Preview',
        postButton: 'Post'
    },
    delete: {
        deleteLabel: 'Delete memorial',
        confirmDeleteLabel: 'Do you want to delete this memorial?',
        delete: 'Delete',
        keepMemorial: 'Keep memorial'
    },
    preview: {
        header: {
            label: 'Preview'
        }
    },
    guestBook: {
        drawerHeader: 'Guest book'
    },
    dedications: {
        drawerHeader: 'Add dedication',
        publishLabel: 'Post',
        dedicationsLabel: 'Dedications',
        deleteDedicationLabel: 'Delete',
        dedicationPlaceholder: 'Write your dedication',
        buttonLabel: 'Upload photo/video',
        buttonLoadingLabel: 'Loading',
        replaceContentLabel: 'Replace this content',
        deleteContentLabel: 'Delete this content',
        cancelLabel: 'Cancel',
        emptyState: {
            title: 'No dedications yet',
            subtitle: 'Share your thoughts and feelings. Dare to be the first to leave a dedication!'
        }
    },
    share: {
        shareThisMemorial: 'Share this memorial'
    },
    notFound: {
        title: 'Memorial not found',
        subtitle: 'The memorial you are looking for does not exist or has been deleted',
        button: { 1: 'Go to memorial', 2: 'Go to home' }
    }
};

export default memorials;
