import { Box, CircularProgress } from '@mui/material';

const LoadingScreen = () => {
    return (
        <div className="kt-splash-screen" style={{ height: '100%', width: '100%' }}>
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <CircularProgress />
            </Box>
        </div>
    );
};

export default LoadingScreen;
