import { Box, Stack } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Otp, { OtpFormValues } from './components/Otp';
import RegisterForm from './components/RegisterForm';
import useConfig from 'hooks/useConfig';
import { useCreateUserMutation, useLoginWithFirebaseTokenMutation, useOtpAuthMutation } from 'api/users.service';
import showToastMessage from 'utils/toast/toast';
import { useTranslation } from 'react-i18next';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../../../../../firebase/config';
import LoadingScreen from 'ui-component/Loading';
import { useLoginPasswordLessMutation } from 'api/email.service';
import { useDispatch } from 'react-redux';
import { setUserInfo } from 'store/slices/user';

const SharedLegacy = () => {
    const { isLoggedIn, login2 } = useAuth();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const [step, setStep] = useState(1);
    const [registerInfo, setRegisterInfo] = useState({ name: '', lastName: '' });
    const [otpAuth] = useOtpAuthMutation();
    const { REACT_APP_FIREBASE_ASSETS_BASE_URL } = useConfig();
    const [isValidOtpCode, setIsValidOtpCode] = useState(true);
    const [createUser, { isLoading: createUserLoading, isSuccess: createUserSuccess }] = useCreateUserMutation();
    const [loginPasswordLess, { isLoading: loginPasswordLessLoading, error: passwordlessError }] = useLoginPasswordLessMutation();
    const [loginWithFirebaseToken, { isLoading: loginWithFirebaseTokenLoading, isSuccess: loginWithFirebaseTokenSuccess }] =
        useLoginWithFirebaseTokenMutation();

    const queries = new URLSearchParams(location.search);
    const email = queries.get('email');
    const legacyId = params.legacyId;

    useEffect(() => {
        const checkSessionHandler = async () => {
            if (isLoggedIn) {
                navigate(`/app/legacy/${legacyId}`, { replace: true });
            }
        };

        checkSessionHandler();
    }, [isLoggedIn, legacyId, navigate]);

    const handleNextStep = async () => {
        try {
            console.log({ language: i18n.language });

            const language = i18n.language.includes('en') ? 'en' : 'es';
            await loginPasswordLess({ to: email || '', language }).unwrap();
            setStep(2);
        } catch (error) {
            console.log({ passwordlessError });

            showToastMessage('error', t('common.errors.genericError'));
        }
    };

    const handleSubmitSignUp = async (otpCustomToken: string) => {
        console.log({ otpCustomToken, email, registerInfo });

        try {
            if (otpCustomToken && email) {
                const firebaseLogin = await signInWithCustomToken(auth, otpCustomToken);

                const signUpResponse = await createUser({
                    uid: firebaseLogin.user.uid, // lo devuelve firebase
                    email: email || '',
                    name: registerInfo.name || email,
                    lastName: registerInfo.lastName || email
                }).unwrap();

                localStorage.setItem('jwt', signUpResponse.jwt);
                login2({
                    email: signUpResponse.email,
                    name: signUpResponse.name
                });
                navigate(`/app/legacy/${legacyId}`, { replace: true });

                return;
            }
        } catch (error: any) {
            if (error.code === 409) {
                showToastMessage('error', t('common.errors.phoneAlreadyUse'));
                return;
            }

            showToastMessage('error', t('common.errors.genericError'));
        }
    };

    const handleVerifyOtp = async (value: OtpFormValues) => {
        try {
            const otpAuthResponse = await otpAuth({
                method: 'email',
                code: value.otpCode,
                email: email
            }).unwrap();

            if (otpAuthResponse.tag === 'register') {
                await handleSubmitSignUp(otpAuthResponse.firebaseToken);
                return;
            }

            const firebaseLogin = await signInWithCustomToken(auth, otpAuthResponse.firebaseToken);
            const firebaseToken = await firebaseLogin.user.getIdToken();

            const loginWithFirebaseTokenResponse = await loginWithFirebaseToken({
                firebaseToken
            }).unwrap();

            dispatch(setUserInfo(loginWithFirebaseTokenResponse));

            localStorage.setItem('jwt', loginWithFirebaseTokenResponse.jwt);
            login2({
                email: loginWithFirebaseTokenResponse.email,
                name: loginWithFirebaseTokenResponse.name
            });

            navigate(`/app/legacy/${legacyId}`, { replace: true });
        } catch (error: any) {
            if (error.code === 401) {
                setIsValidOtpCode(false);
                return;
            }
            showToastMessage('error', t('common.errors.genericError'));
        }
    };

    if (loginWithFirebaseTokenLoading) {
        return null;
    }

    return (
        <Stack
            direction="row"
            height="100%"
            minHeight="100vh"
            sx={{
                backgroundImage: `url(${REACT_APP_FIREBASE_ASSETS_BASE_URL}/BackgroundShareLegacy.webp)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <Box width={{ xs: '0%', md: '50%' }}></Box>
            <Stack width={{ xs: '100%', md: '50%' }} justifyContent="center" alignItems="center" margin={0}>
                <Box
                    width={{ xs: '100%', md: 'auto' }}
                    height={{ xs: '100%', md: '90%' }}
                    padding={{ xs: '20px', md: '38px 50px' }}
                    borderRadius={{ xs: '0px', md: '16px' }}
                    maxHeight="709px"
                    sx={{ backgroundColor: '#fff', aspectRatio: '4/5' }}
                >
                    {step === 1 &&
                        !createUserLoading &&
                        !createUserSuccess &&
                        !loginWithFirebaseTokenSuccess &&
                        !loginWithFirebaseTokenLoading &&
                        !loginPasswordLessLoading && (
                            <RegisterForm handleNextStep={handleNextStep} registerInfo={registerInfo} setRegisterInfo={setRegisterInfo} />
                        )}
                    {step === 2 &&
                        !createUserLoading &&
                        !createUserSuccess &&
                        !loginWithFirebaseTokenSuccess &&
                        !loginWithFirebaseTokenLoading &&
                        !loginPasswordLessLoading && (
                            <Otp
                                email={queries.get('email') || ''}
                                handleBack={() => setStep(1)}
                                handleVerifyOtp={handleVerifyOtp}
                                isValidOtpCode={isValidOtpCode}
                                resendOtp={handleNextStep}
                            />
                        )}
                    {(createUserLoading ||
                        createUserSuccess ||
                        loginWithFirebaseTokenLoading ||
                        loginWithFirebaseTokenSuccess ||
                        loginPasswordLessLoading) && <LoadingScreen />}
                </Box>
            </Stack>
        </Stack>
    );
};

export default SharedLegacy;
