import { Box, Stack, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CustomLink from 'ui-component/CustomLink';
import BeSoulLogo from 'ui-component/Icons/BeSoulLogo';
import Illustration404 from 'ui-component/Illustrations/Illustration404';
import Text from 'ui-component/Text/Text';

const NotFound = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Stack width="100vw" height="100vh" alignItems="center" justifyContent="center">
            <Helmet>
                {process.env.REACT_APP_IS_PROD_ENV === 'false' && <meta name="robots" content="noindex, nofollow" />}
                <link rel="canonical" href="https://besoul.app/app/memorial/feed" />
                <meta name="title" content={t('common.seo.title')} />
                <meta name="description" content={t('common.seo.description')} />
                <title>{t('common.seo.title')}</title>
            </Helmet>
            <Stack direction="column" alignItems="center">
                <BeSoulLogo width="252" style={{ marginBottom: '20px' }} />
                <Illustration404 width="90%" />
                <Box>
                    <Text preset={{ xs: 'titleLarge', sm: 'headlineSmall', md: 'displaySmall' }} sx={{ xs: { textAlign: 'center' } }}>
                        <strong style={{ color: '#182E39' }}>{t('common.error404.title.1')}</strong>
                        {t('common.error404.title.2')}
                    </Text>
                    <Text preset={{ xs: 'titleLarge', sm: 'headlineSmall', md: 'displaySmall' }} sx={{ xs: { textAlign: 'center' } }}>
                        {t('common.error404.description')}
                    </Text>
                </Box>

                <CustomLink
                    to="/"
                    sx={{
                        marginTop: '20px',
                        maxWidth: '338px',
                        width: '90%',
                        padding: '16px',
                        borderRadius: '12px',
                        backgroundColor: theme.palette.secondary.main
                    }}
                >
                    <Text preset={{ xs: 'titleSmall' }} textColor="#fff" sx={{ xs: { textAlign: 'center' } }}>
                        {t('common.error404.button')}
                    </Text>
                </CustomLink>
            </Stack>
        </Stack>
    );
};

export default NotFound;
