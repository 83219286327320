// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import userReducer from './slices/user';
import { memorialApi } from 'api/memorial.service';
import { usersApi } from 'api/users.service';
import { emailApi } from 'api/email.service';
import { authApi } from 'api/auth.service';
import { legacyApi } from 'api/legacy.service';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    [memorialApi.reducerPath]: memorialApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [legacyApi.reducerPath]: legacyApi.reducer,
    menu: menuReducer,
    snackbar: snackbarReducer,
    user: userReducer
});

export default reducer;
