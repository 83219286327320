import { Box, Input, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import ArrowLeft from '../../../../../../assets/icons/svg/arrow_left.svg';
import LetterIcon from '../../../../../../assets/icons/svg/letter_icon.svg';
import { useTranslation } from 'react-i18next';
import Text from 'ui-component/Text/Text';
import { useFormik } from 'formik';
import CustomButton from 'ui-component/CustomButton';

interface OtpProps {
    email?: string;
    handleVerifyOtp: (values: OtpFormValues) => void;
    handleBack: () => void;
    isValidOtpCode: boolean;
    resendOtp: () => void;
}
export interface OtpFormValues {
    otpCode: string;
}

const Otp = ({ email, handleVerifyOtp, handleBack, isValidOtpCode, resendOtp }: OtpProps) => {
    const { t, i18n } = useTranslation();
    const [timeToResendCode, setTimeToResendCode] = useState<number>(60);

    const formik = useFormik({
        initialValues: {
            otpCode: ''
        },
        validate: (values) => {
            const errors: Partial<OtpFormValues> = {};

            if (!values.otpCode) {
                errors.otpCode =
                    i18n.language === 'en-US' ? "Don't forget to complete all the fields" : 'No olvides completar todos los campos';
            }

            if (!/^[a-zA-Z0-9]+$/.test(values.otpCode)) {
                errors.otpCode = i18n.language === 'en-US' ? 'The OTP code must be alphanumeric' : 'El código OTP debe ser alfanumérico';
            }

            return errors;
        },
        onSubmit: async (values: OtpFormValues) => {
            handleVerifyOtp(values);
        }
    });

    useEffect(() => {
        if (timeToResendCode > 0) {
            setTimeout(() => {
                setTimeToResendCode(timeToResendCode - 1);
            }, 1000);
        }
    }, [timeToResendCode]);

    useEffect(() => {
        if (!isValidOtpCode) {
            formik.errors.otpCode = i18n.language === 'en-US' ? 'Enter a valid code' : 'Ingrese un código válido';
        }
    }, [isValidOtpCode]);

    return (
        <Box component="section" width="100%">
            <Box
                component="img"
                justifyContent="start"
                width="max-content"
                src={ArrowLeft}
                onClick={handleBack}
                sx={{ cursor: 'pointer' }}
            />
            <Stack direction="column" justifyContent={{ xs: 'center', md: 'start' }} alignItems="center" height="100%" width="100%">
                <Box component="img" src={LetterIcon} height="146px" width="146px" />
                <Text
                    preset="titleLarge"
                    fontFamily="Merriweather"
                    fontWeight={700}
                    width={{ md: '100%' }}
                    sx={{ xs: { marginBottom: '10px', marginTop: '15px' } }}
                >
                    {t('login.otp.title')}
                </Text>
                <Text preset={{ md: 'bodyLarge' }} width={{ md: '100%' }}>
                    {t('login.otp.subtitle')}
                </Text>
                <Text preset={{ md: 'labelLarge' }} width={{ md: '100%' }} sx={{ xs: { marginBottom: '15px' } }}>
                    {email}
                </Text>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <Text preset="titleSmall" textColor="#889EA8" fontWeight={600} sx={{ xs: { marginBottom: '5px' } }}>
                        {t('login.otp.input.label')}
                    </Text>
                    <Box width="100%" marginBottom="20px">
                        <Input
                            disableUnderline
                            fullWidth
                            sx={{
                                borderRadius: '4px',
                                backgroundColor: '#EFF2F7',
                                border: `1px solid ${
                                    (formik.touched.otpCode && Boolean(formik.errors.otpCode)) ||
                                    (!isValidOtpCode && Boolean(formik.errors.otpCode))
                                        ? 'red'
                                        : '#455A6480'
                                }`,
                                padding: '10px 16px'
                            }}
                            inputProps={{ maxLength: 6 }}
                            id="otpCode"
                            name="otpCode"
                            value={formik.values.otpCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                (formik.touched.otpCode && Boolean(formik.errors.otpCode)) ||
                                (!isValidOtpCode && Boolean(formik.errors.otpCode))
                            }
                        />
                        {formik.touched.otpCode && (
                            <Text preset="labelSmall" textColor="red">
                                {formik.errors.otpCode}
                            </Text>
                        )}
                        {!isValidOtpCode && !formik.touched.otpCode && (
                            <Text preset="labelSmall" textColor="red">
                                {formik.errors.otpCode}
                            </Text>
                        )}
                    </Box>

                    <CustomButton
                        disabled={!formik.isValid || !Boolean(formik.values.otpCode)}
                        variant="contained"
                        type="submit"
                        buttonColor="secondary"
                        padding={{ xs: '14px 0' }}
                        width={{ xs: '100%', sm: '100%', lg: '436px' }}
                        margin="0 auto"
                        sx={{
                            xs: { borderRadius: '14px' }
                        }}
                    >
                        <Text preset={{ md: 'titleMedium' }} textColor="inherit" fontWeight={600}>
                            {t('login.otp.button')}
                        </Text>
                    </CustomButton>
                    <CustomButton
                        variant="outlined"
                        padding={{ xs: '14px 0' }}
                        width={{ xs: '100%', sm: '100%', lg: '436px' }}
                        margin="0 auto"
                        buttonColor="secondary"
                        sx={{
                            xs: { borderRadius: '14px', marginTop: '12px', border: '1px solid #ECEFF1' }
                        }}
                        onClick={
                            timeToResendCode === 0
                                ? () => {
                                      resendOtp();
                                      setTimeToResendCode(60);
                                  }
                                : () => {}
                        }
                    >
                        <Text preset={{ md: 'titleMedium' }} textColor="inherit" fontWeight={600} disabled={timeToResendCode > 0}>
                            {t('login.otp.resend')}
                            {timeToResendCode > 0 && (
                                <Text
                                    preset="titleMedium"
                                    textColor="inherit"
                                    component="span"
                                    fontWeight={600}
                                    sx={{ xs: { marginLeft: '5px' } }}
                                >
                                    ({timeToResendCode} seg)
                                </Text>
                            )}
                        </Text>
                    </CustomButton>
                </form>
            </Stack>
        </Box>
    );
};

export default Otp;
